@font-face {
	font-family: "passion_one_black";
	src: url(./PassionOne-Black.ttf) format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "passion_one_bold";
	src: url(./PassionOne-Bold.ttf) format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "passion_one_regular";
	src: url(./PassionOne-Regular.ttf) format('truetype');
	font-weight: 400;
	font-style: normal;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
	
}

html, body {
	position: relative;
	height: 100%;
	width: 100%;
	background: #F4E5CA;
	margin: 0;
	font-family: 'Montserrat', sans-serif;
	box-sizing: border-box;
}

body {
	overflow-x: hidden;
}

img {
	display: block;
	max-width: 100%;
}

main {
  min-height: 100vh;
  padding: 8vh 0 0 0;
}


/*---------text styles-----------*/

h1, h2, h3, h4, p {
	margin: 0;
}

h1 {
	font-family: 'passion_one_black', sans-serif;
	font-size: 2rem;
}

h2 {
	font-family: 'passion_one_bold', sans-serif;
	color: #FF1C31;
}

h3 {
	font-size: 1.5rem;
	font-weight: 300;
}

.nav_name {
	font-size: 2.5rem;
}

.section_title {
	position: relative;
	left: 0;
	font-size: 1.5rem;
	font-weight: 600;
	color: #000000;
	padding: .5rem 0;
	text-align: center;
	text-shadow: -.15rem .15rem 0 #BCAD96;
	border-bottom: 2px solid #000000;
	width: 50%;
	margin: 0 auto;
	transition: all .5s ease;
}

/*---------end text styles-----------*/

#scroll_container {
	
/*
	height: 100vh;
	overflow-y: auto;
	overflow-x: hidden;
	scroll-behavior: smooth;
	scroll-snap-type: y proximity;
*/
}

#top,
#project_section,
#about_section,
footer {
	scroll-snap-align: none;
 }

#project_section {
	padding: 1px 0 4em 0;
}

#intro_nav {
	display: none;
}




.amp_wrap {
	position: relative;
	display: inline-block;
}

.amp {
	display: inline-block;
	/* color: rgba(188,78,212,1); */
/*	border: 2px solid #000;*/
}

.amp2 {
	display: inline-block;
/*	border: 2px solid red;*/
	position: relative;
	z-index: 2;
	top: 0;
	left: -50%;
	color: #000;
}

.amp_drop {
	top: 500%;
	transition: top ease 3s;
}

.red {
	color: red;
	transform: translateY(50vh);
	transition: all ease .35s;
}



.content_wrapper {
	margin: 0 auto;
}

header {
	position: fixed;
	top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 99;
}

.nav {
	height: 6vh;
	width: 92%;
	margin: auto;
	padding: .75vh;
	justify-content: space-between;
	align-items: center;
}

.nav_logo {
	width: 4.5vh;
	height: 4.5vh;
	padding: 0 .5vh;
	fill: #fff;
}


#nav_links {
	display: none;
	letter-spacing: .2rem;
	height: 2em;
}

#nav_links a {
	position: relative;
	top: 0;
	transition: top ease .35s, padding ease .35s;
	color: #F4E5E9;
	display: flex;
	text-transform: uppercase;
	padding: .5em .5em .25em .5em;
}

#nav_links a:hover {
	top: -.35em;
	padding: .5em .5em .6em .5em
}

.hamburger_div {
	display: block;
	cursor: pointer;
	transition: transform ease .2s;
}

.hamburger_lines,
.hamburger_div::before,
.hamburger_div::after {
	position: relative;
	display: block;
	content: "";
	background: #ffffff;
	height: 3px;
	width: 30px;
	border-radius: 2px;
	margin: 6px 0;
}


.rotate {
	transform: rotate(-90deg);
}

.rotate .hamburger_lines {
	transform: rotate(-45deg);
	transition: transform ease .35s;
}

.rotate::before {
	transform: rotate(45deg) translate(6px, 6px);
/*	transform: rotate(-135deg) translate(-6px, -6px);*/
	transition: transform ease .35s;
}

.rotate::after {
	opacity: 0;
	transition: opacity ease .2s;
}

.mobile_nav_container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 4;
  transition: background-color .35s ease;
  pointer-events: none;
}

.mobile_nav_container.show {
  background-color: rgba(0, 0, 0, .68);
}

.mobile_nav {
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100vh;
	transform: translateX(100%);
	background: #181717;
	overflow: hidden;
	overflow-y: scroll;
	color: #ffffff;
	display: block;
	transition: all ease .35s;
	pointer-events: all;
	padding: 6vh 0 0 0;
	
}

.mobile_nav.slide {
  transform: translateX(0);
  box-shadow: 0 0 1em;
  transition: all ease .35s;
}

.noscroll {
	overflow: hidden;
}

.no-scroll-active {
	overflow: hidden !important;
}

.mobile_nav ul {
	height: 90vh;
	list-style: none;
	display: flex;
	flex-direction: column;
	padding: 0 0 0 0;
	margin: .25em 1em;
/*	background: yellow;*/
	font-size: 1.25rem;
}

.mobile_nav a {
	text-decoration: none;
	color: #ffffff;
/*	background: orange;*/
	margin: 0 2em;
	padding: 1em 0;
	border-bottom: 2px solid #969696;
}

.mobile_nav li {
	background: #1E1E1E;
	padding: .5em 1em;
	display: flex;
}

.border_top {
	border-top: 2px solid #ffffff;
}

.nav_arrow {
	width: 1rem;
	height: 1rem;
	margin: auto 0 auto auto;
	border: solid #ffffff;
	border-width: 0 2px 2px 0;
}

.carousel_arrow {
	width: 1rem;
	height: 1rem;
	border: solid #9B9597;
	border-width: 0 3px 3px 0;
	transition: all ease .3s;
}

.small_arrow {
	width: 25%;
	height: 25%;
	margin: auto 0;
	transform: translate(85%, 85%);
}

.right {
	transform: rotate(-45deg);
}

.left {
	transform: rotate(135deg);
}

.active {
	border: 1px solid #FF1C31;
	animation: fadeUnderline .5s ease 1;
}

.fade {
	opacity: 0%;
}

#above_fold {
	padding: 8vh 0 0 0;
}

#intro_grid {
	display: grid;
	width: 100%;
	margin: 0 auto;
  padding: 0 .75em;
	grid-template-rows: auto 60vh;
	grid-template-columns: auto auto 4fr auto;
	grid-template-areas: 
		"title title title title"
		"left left animation animation"
		;
/*	background: orange;*/
	padding-bottom: 8em;
}

.intro_text {
	grid-area: title;
	margin: 1.5em 1em;
/*	background: orange;*/
}



.intro_text::before {
	display: inline-block;
	content: "";
	position: static;
	width: 100%;
	border-top: 2px solid #000000;
	padding: .25em 0 0 0;
	background: transparent;
	animation:  revealLeftToRight .75s ease 1;
}



.logo_animation {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	grid-area: animation;
}

.logo_animation > img {
	padding: 0 0 0 1em;
}

#mountains {
	padding: 0 1rem 20% 1rem;
	width: 90%;
}

#cacti_animation {
	position: absolute;
	width: 40%;
	padding: 0 0 8% 10%;
}

.icon_list_left {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
/*	background: green;*/
	padding: 1em .4em;
	border-bottom: 1px solid #000000;
}

.icon_list_left > a {
	margin: .2em 0;
}

.icon_list_left svg {
	width: 25px;
	height: 25px;
}

.icon_list_right {
	display: none;
}

.section_header {
	position: sticky;
	top: 7.4vh;
	transition: all .5s ease;
}

/* .section_header p {
	transition: all .5s ease
} */

.header_margin {
	margin: 5em 0 0 0;
	width: 100%;
}

.break_background {
	background: #E8D9BF;
}

.flex {
	display: flex;
}

.bkgd_primary {
	background: #F4E5CA;
}

.bkgd_secondary {
	background: #EDDCC0;
}

.bkgd_tertiary {
	background: #000000;
}



.column_left {
	margin: 0 0 1em 0;
	grid-area: left;
	display: flex;
	flex-direction: column;
}

.vert_proj,
.vert_proj:visited {
	color: inherit;
	text-decoration: none;
}

.vertical_text {
	display: inline;
	font-size: 1rem;
	line-height: 2.5rem;
	writing-mode: vertical-rl;
	text-orientation: mixed;
	margin: 1em auto;
	cursor: pointer;
}

/*
.vertical_line {
	flex-shrink: 0;
	height: 10px;
	width: 20px;
	background: red;
}
*/



.vertical_line {
	flex-shrink: 0;
	height: 25vh;
	margin: 0 0 0 1em;
}



.vertical_line::before {
	display: inline-block;
	content: '';
	position: static;
	height: 100%;
	border-left: 1px solid #FF1C31;
	animation:  revealTopToBottom .75s ease 1;
}

.downward_arrows,
.downward_arrows2{
	display: block;
	width: 1.5em;
	margin: 0 auto;
	transition: opacity ease 1s;
	z-index: 2;
}


.column_right {
	flex-grow: 1;
	margin: 0 1em 0 0;
}


/*<-------- projects section ---------->*/

.full_screen_img {
	position: fixed;
	top: 40%;
	left: 40%;
	width: 25%;
	height: 25%;
	background: rgba(0, 0, 0, .95);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 3;
	cursor: zoom-out;
	opacity: 0;
	transition: all .25s ease;
}

.full_screen_img.active {
	top: 0;
	left: 0;
	opacity: 1;
	width: 100%;
	height: 100%;
	border: none;
}

.full_screen_img img {
	object-fit: contain;
	width: 100%;
	height: 100%;
}

.full_screen_img_light {
	background: rgba(251, 244, 223, .97);
}

/*
.vertical_line::before {
	display: inline-block;
	content: '';
	position: static;
	height: 100%;
	border-left: 1px solid #FF1C31;
	animation:  revealTopToBottom .75s ease 1;
}
*/



.project_col_wrapper {
	width: 100%;
	display: flex;
	align-items: flex-start;
	margin: 4em 0 4em 0;
	
}

.project_col_wrapper::before {
	order: 2;
	display: inline-block;
	content: '';
	position: static;
	border-left: 2px solid #000000;
	height: 100%;
}


.proj_col_one,
.proj_col_two {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 auto;
	padding: 2em 0 0 0;
}

.proj_col_one {
	order: 1;
}

.proj_col_two {
	order: 3;
}

.proj_type {
	margin: 0 0 2em 0;
	text-align: center;
	color: #FF1C31;
	font-weight: 600;
}

.card {
	width: 75%;
	margin: 0 auto 3em auto;
	background: #000000;
	border: 5px solid #000;
}

.proj_title {
	text-align: center;
	color: #FFF;
	text-decoration: none;
}

.proj_title,
.proj_title::visited {
	text-decoration: none;
}

.img_container {
	display: flex;
	overflow: hidden;
}

.proj_img {
	width: 100%;
	transition: transform .4s ease;
}

.proj_title {
	margin: .25em 0;
}

/*

.carousel_wrapper {
	display: grid;
	width: 100%;
	height: 50vh;
	margin: 10em auto;
	padding: 3% 0;
	grid-template-rows: 85% auto;
	grid-template-columns: auto 75% auto;
	grid-template-areas: 
		"card card card"
		"arrowL ind arrowR"
		;
}

.carousel_arrow_left {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	grid-area: ind;
	padding: 1em 0 0 3em;
}



.carousel_arrow_right {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	grid-area: ind;
	padding: 1em 3em 0 0;
}

.carousel_arrow_left:hover .carousel_arrow,
.carousel_arrow_right:hover .carousel_arrow,
.carousel_arrow_left:focus .carousel_arrow,
.carousel_arrow_right:focus .carousel_arrow {
	border-color: #FF1C31;
}

.carousel_indicator_wrapper {
	display: flex;
	grid-area: ind;
	justify-content: center;
	align-items: center;
	padding-top: 1em;
}

.indicator {
	background: #FF1C31;
	border-radius: 50%;
	width: 8px;
	height: 8px;
	margin: 5px;
	opacity: .75;
}

.active_dot {
	height: 14px;
	width: 14px;
	opacity: 1;
	transition: all ease .5s;
}
*/

/*
.project_card_wrapper {
	display: grid;
	grid-area: card;
	box-sizing: content-box;
	overflow: hidden;
}
*/

.project_link {
	grid-column: 1;
	grid-row: 1;
	text-decoration: none;
}

.project_card {
	height: 100%;
	width: 100%;
	background: #F2EAE7;
	display: none;
	visibility: hidden;
	overflow: hidden;
	padding: 7px 7px 0 7px;
	box-sizing: border-box;
	grid-column: 1;
	grid-row: 1;
	border-left: 25px solid rgba(0,0,0,1);
	border-right: 25px solid rgba(0,0,0,1);
}

.project_image_wrapper {
	height: 80%;
	width: 100%;
	margin: 0 auto;
	overflow: hidden;
}

.project_image {
	height: 100%;
	width: 100%;
	margin: auto;
	background-color: #2B2B2B;
	background-position: center;
	background-size: cover;
	background-repeat: none;
	transition: all .5s ease;
}

/* .project_1 {
	background-image: url("media/FLying_Nickel_header2.jpg");
}

.project_2 {
	background-image: url("media/tatooed_can_mockup_purp.jpg");
}

.project_3 {
	background-image: url("media/jazz_poster_mockup.jpg");
}

.project_4 {
	background-image: url("media/to-do_mockup_purple.jpg");
}

.project_5 {
	background-image: url("media/to-do-app-mockup.jpg");
} */


.project_card_wrapper:hover .project_image,
.project_card_wrapper:focus .project_image {
	transform: scale(1.10);
}
	

.project_card_text {
	display: flex;
	justify-content: space-between;
	height: 20%;
	align-items: center;
	padding: 0 1em;
}

.project_name {
	font-size: 1.5rem;
	color: #000000;
	font-weight: 400;
	text-transform: capitalize;
	border-right: 2px solid #FF1C31;
	padding: 0 .5em 0 0;
	margin: 0 .5em 0 0;
	display: block;
}

#more_work {
	cursor: pointer;
	text-align: center;
	font-size: 1rem;
	font-weight: 500;
	background: #FF1C31;
	color: white;
	padding: .75em 0;
	width: 60vw;
	margin: 1em auto;
	border: 3px solid #000000;
	transition: all ease .5s;
}

#more_work:hover {
	background: #fc7703;
}

.no_decoration {
	text-decoration: none;
}

/*<-------- end projects section ---------->*/

.about_wrapper {
	background: none;
}

.about_img_container {
	border: 3px solid #000000;
	width: 10em;
	height: 13.5em;
	margin: 5em auto 0 auto;
	border-radius: 15em 15em 0 0;
	background-image: url("media/github_pic.jpg");
	background-position: top;
	background-size: cover;
}

.about_text_wrapper {
	width: 85%;
	display: flex;
	flex-direction: column;
	margin: 2em auto;
	align-items: center;
}

.about_text {
	margin: 0 0 2em 0;
}

.about_text h3,
.about_text h4 {
	font-weight: 500;
	text-align: center;
}

.about_text h3 {
	font-size: 2rem;
}

.about_text h4 {
	font-weight: 600;
	margin-bottom: 1.5em;
}


.about_text p {
	line-height: 1.75rem;
	padding: 1em 0 0 0;
}

.achievement_box {
	width: 90%;
	border: 3px solid #000000;
	text-align: center;
	margin: 0 auto 10em auto;
	padding: 0 0 2em 0;
}

.ach_title {
	font-weight: 500;
	font-size: 2rem;
	padding: 2rem;
	text-decoration: underline;
	text-underline-offset: 10px;
}




.achievements_wrapper {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
}

.achievement {
	display: flex;
	align-items: center;
	justify-content: space-around;
	text-align: left;
}

.img_box {
	position: relative;
	width: 10%;
	padding-bottom: 10%;
	border: 3px solid #000000;
	margin: .25em 0 .25em 1em;
	border-radius: 20%;
	box-shadow: -3px 3px 0px black;
}

.ach_img {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 80%;
	margin: auto;
}

.achievement p {
	width: 80%;
}


.cert {
	transform: translateY(8%);
}


footer {
	background: #000000;
	font-size: .75rem;
	padding: 3em;
	color: #ffffff;
	
}

.footer_content div {
	display: flex;
	padding: 1em 0;
	justify-content: center;
}

.logo_footer {
	width: 5em;
	height: 5em;
	fill: #fff;
}

.svg_icon_footer {
	width: 2em;
	height: 2em;
	margin: .5em;
	fill: #fff;
}

.copyright {
	flex-direction: column;
	padding-bottom: .5em;
}

.email_el {
	text-decoration: none;
	color: #ffffff;
}

/*----- section intersection obserer styles -----*/

.active_section {
	background: #F9F1E4;
}

.active_section p {
	text-shadow: none;
	width: 100%;
	transition: all .5s ease;
}



/*---------media queries-----------*/

@media (min-width: 800px) {
	html {
		scroll-snap-type: y proximity;
	}
	
	h1 {
		font-size: 4rem;
		line-height: .9em;
		padding: .2em 0 0 0;
		letter-spacing: .02em;
	}
	
	h3 {
		font-size: 2rem;
		font-weight: 300;
	}

	#intro_nav {
		display: block;
	}
	
	
	
	



	
	.content_wrapper {
		width: 90%;
	}
	
	.nav {
		justify-content: space-between;
		height: 7vh;
	}
	
	.hamburger_div {
		display: none;
	}
	
	.nav > img {
		width: auto;
	}
	
	.nav_logo {
		width: 5vh;
		height: 5vh;
	}
	
	.nav_name {
		padding-left: .65em;
		flex-grow: 1;
	}
	
	#nav_links {
		display: flex;
		text-transform: uppercase;
		font-size: .8rem;
	}

	.nav_item {
		cursor: pointer;
	}
	
	.active {
		border: none;
		border-bottom: 2px solid #FF1C31;
		animation: fadeUnderline .5s ease 1;
	}
	
	
	ul {
		list-style: none;
	}
	
	li > a {
		margin: 0 .7em;
		text-decoration: none;
	}
	
	#intro_grid {
		display: grid;
		width: 100%; 
		margin: 0 auto;
    padding: 0 0 8em 0;
		grid-gap: 1em;
		grid-template-rows: 40vh 40vh;
		grid-template-columns: auto 6fr auto auto;
		grid-template-areas: 
			"left title ."
			"left animation icons"
			;
/*		background: orange;*/
	
	}
	
	.intro_text {
		display: flex;
		flex-direction: column;
		width: 100%;
		grid-area: title;
		margin: 3em 0 1.5em 0;
		padding: 3em 0 0 0;
		
	}
	
	.intro_text::before {
		width: 80%;
		animation:  revealLeftToRightDesktop .75s ease 1;
	}
	
	.icon_list_left {
		display: none;
	}
	
	.icon_container {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		grid-area: icons;
		transform: translateX(7vw);
	}
	
	.icon_list_right {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		border-left: 2px solid #000000;
		margin-bottom: 4em;
	}
	
	.icon_list_right > a {
		display: flex;
		margin: 5px;
		text-decoration: none;
		align-items: center;
	}
	
	.label_wrap {
		transform: translateX(-25px);
		z-index: -1;
		overflow: hidden;
	}
	
	.icon_label {
		font-size: 1.25rem;
		transform: translateX(-175%);
		z-index: -1;
		transition: all .3s ease;
		color: #000000;
	}
	
	.icon_img {
		transform: translateX(-50px);
		height: 100%;
	}
	
	.svg_icon_main {
		transform: translateX(-50px);
		height: 35px;
		width: 35px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		overflow: visible;
	}
	
	.svg_icon_main:hover {
		fill: #FF1C31;
	}
	
	.svg_icon_footer:hover {
		fill: #AFAFAF;
	}

	
	.icon_img:hover ~ .label_wrap > .icon_label,
	.svg_icon_main:hover ~ .label_wrap > .icon_label{
		transform: translateX(0%);
	}
	
	.column_left {
		margin: 1em 1.5em 1em .5em;
		height: 140%;
	}
	
	
	.vertical_line {
		height: 65vh;
		margin: 0 auto;
	}
	
	.vertical_line::before {
		border-left: 2px solid #FF1C31;
	}

	
	.downward_arrows2 {
		display: inherit;
		z-index: 4;
	}
	
	
	.logo_animation {
		grid-area: animation;
		display: flex;
		justify-content: flex-end;
		padding: 0 0 0 0;
	}
	
	#mountains {
		padding: 0 0 5% 0;
		width: 65%;
	}
	
	#cacti_animation {
		position: absolute;
		height: 50%;
		width: auto;
		padding: 0 9% 0 0;
	}
	
	
	
/*<-------- projects section ---------->*/	
	
	.project_col_wrapper {
		flex-direction: column;
	}
	
	.proj_type {
		text-align: left;
		font-size: 1.25rem;
		padding: .5rem 0 .5rem 5rem;
		margin: 2rem 0 2rem 0;
		background: #DDCDB2;
	}
	
	.project_card_wrapper {
		display: flex;
		
	}
	
	.card {
		margin: 0 2.5vw;
	}
	
	.card:hover img,
	.card:focus img {
		transform: scale(1.10);
		z-index: 2;
	}

	
	.proj_title {
		font-size: 1.25rem;
		margin: .25em 0;
	}
	
	
	
	
	
	
	
	
	
	
	.carousel_wrapper {
		display: grid;
		width: 65%;
		height: 70vh;
		margin: 10em auto;
		padding: 3% 0;
		border-top: none;
		border-bottom: none;
		grid-template-rows: 95% 5%;
		grid-template-columns: auto 75% auto;
		grid-template-areas: 
			"arrowL card arrowR"
			". ind ."
			;
	}
	
	.carousel_arrow {
		width: 2rem;
		height: 2rem;
		border: solid #9B9597;
		border-width: 0 3px 3px 0;
		transition: all ease .3s;
	}
	
	.carousel_arrow_left {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-end;
		grid-area: arrowL;
	}

	.carousel_arrow_right {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		grid-area: arrowR;
	}
	
	.small_arrow {
		width: 35%;
		height: 35%;
		margin: auto 0;
		transform: translate(75%, 75%);
	}

	.carousel_arrow_left:hover .carousel_arrow,
	.carousel_arrow_right:hover .carousel_arrow,
	.carousel_arrow_left:focus .carousel_arrow,
	.carousel_arrow_right:focus .carousel_arrow {
		border-color: #FF1C31;
	}
	
	.project_card {
		border-left: 50px solid rgba(0,0,0,1);
		border-right: 50px solid rgba(0,0,0,1);
	}

	.project_image_wrapper {
		height: 85%;
		width: 100%;
		margin: 0 auto;
		overflow: hidden;
	}
	
	.project_card_text {
		display: flex;
		justify-content: space-between;
		height: 15%;
		align-items: center;
		padding: 0 1em;
		line-height: 2.5rem;
	}

	.active_card {
		display: block;
		visibility: visible;
	}

	.project_name {
		font-size: 2rem;
		display: inline;
	}
	
	#more_work {
		font-size: 1.5vw;
		width: 13vw;
	}

	
	
	
	
	
/*<-------- end projects section ---------->*/	
	
	.about_wrapper {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}
	
	.about_text_image_wrapper {
		margin: 8em 2em;
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		justify-content: center;
	}
	
	.about_text_wrapper {
		flex-direction: row;
		width: 35%;
		margin: 6em 5vw 0 auto;
		justify-content: center;
		align-items: flex-end;
	}
	
	.about_text h3,
	.about_text h4 {
		text-align: left;
	}
	
	.about_img_container {
		width: 15em;
		height: 21.25em;
		border-radius: 15em 15em 0 0;
		/* background-image: url("media/portfolio_pic.jpg"); */
		background-position: top;
		background-size: cover;
		margin: 0 auto 0 0;
	}
	
	
	.achievements_wrapper {
		display: grid;
		grid-template-rows: auto;
		grid-template-columns: repeat(3, 1fr);
		gap: 10%;
		padding: 3em 4em;
	}

	.achievement {
		width: 100%;
		height: 100%;
		justify-content: flex-start;
		flex-direction: column;
	}
	
	.img_box {
		border: 5px solid #000000;
		width: 65%;
		padding-bottom: 65%;
		margin: 1em auto;
		border-radius: 15%;
		box-shadow: -5px 5px 0px black;
/*		margin: 2em;*/
	}

	.ach_img {
		width: 70%;
	}
	
	.achievement p {
		width: 100%;
		text-align: center;
	}
	
	.cert {
		transform: translateY(8%);
	}
	
	
	
	
	
	footer {
		padding: 5em 0;
	}
	
	.footer_content {
		display: flex;
		flex-direction: row;
		padding: 1em 0;
		justify-content: space-between;
		align-items: center;
	}

	
	.footer_socials {
		order: 2;
	}
	
	.svg_icon_footer {
		
	}
	
	
	.logo {
		order: -1;
	}
	
	.copyright div {
		padding-bottom: .25em;
	}

	.section_header {
		position: sticky;
		top: 8.3vh;
		width: 100%;
		z-index: 3;
	}

	.section_title_container {
		width: 90%;
		margin: 0 auto;
		display: flex;
	}

	.section_title {
		width: auto;
		letter-spacing: 2px;
		text-transform: uppercase;
		font-size: 3rem;
		line-height: 3.5rem;
		border: none;
		display: inline-block;
		text-align: start;
		margin: 0 0 0 0;
		padding: .5rem 5rem;
		transition: all .5s ease;
	}

	.active_section .section_title {
		width: auto;
		transform: translateX(calc(45vw - 52%))
	}
	
	.active_section {
		width: 100%;
		border-bottom: 2px solid #000000;
	}
	

	/* .active_section p {
		width: auto;
		display: inline-block;
		text-shadow: none;
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		margin: 0;
	} */
	
	.full_screen_img {
		padding: 9vh 0 0 0;
		align-items: flex-start;
	}
	
	.full_screen_img img{
		height: 90%;
	}

	.full_screen_img video{
		height: 90%;
		width: 80%;
	}
	
	
}

@media (min-width: 1475px) {
	
	.content_wrapper {
		width: 70%;
	}
	
	#nav_links {
		font-size: 1rem;
	}
	
	
	.achievement p {
		width: 70%;
		text-align: center;
	}

	.section_title_container {
		width: 100%;
	}

	.active_section .section_title {
		width: auto;
		transform: translateX(calc(50vw - 52.25%))
	}
	
	.active_section {
		width: 100%;
	}
	
	.full_screen_img video{
		height: 90%;
		width: 60%;
	}

	.full_screen_img svg{
		height: 80%;
		width: 60%;
	}

	
}
	






/*<--------- project page styles ------------>*/


.above_fold_wrapper {
	overflow-x: hidden;
}

#project_grid {
	display: grid;
	width: 90%;
	margin: 0 auto;
	grid-template-rows: auto auto;
	grid-template-columns: auto auto 4fr auto;
	grid-template-areas: 
		"p_left p_title p_title p_title"
		"p_left p_body p_body p_body"
		;
/*	background: orange;*/
	padding-bottom: 4em;

}

.project_body {
	grid-area: p_body;
	margin: 0 0 0 .5em;
/*	background: green;*/
}

.back_button {
	width: 100%;
	padding: 2em 0 1em 0;
}

.back_button img {
	width: 30px;
	height: 30px;
	cursor: pointer;
}

.project_heading {
	background: #E8D9C4;
	padding: 1em 0;
	width: 160%;
	transform: translateX(-25%);
}

.project_heading p {
	color: #E8D9C4;
	-webkit-text-stroke: 2px #000;
	text-stroke: 2px #000;
	letter-spacing: 2px;
	text-transform: uppercase;
	font-family: 'passion_one_regular', sans-serif;
	font-size: 3.5rem;
	padding: 0 0 0 25%;
/*	background: orange;*/
	max-width: 65%;
	line-height: 3.5rem;
}

.project_title {
	grid-area: p_title;
}


.project_img {
	position: relative;
	z-index: 2;
	display: grid;
	cursor: zoom-in;
}

.project_img img,
.project_img  video {
	width: 100%;
	grid-column: 1;
	grid-row: 1;
	margin: 2em auto;
	border: 2px solid #000;
	box-shadow: 5px 5px 0 #3B301D;
	transition: all ease .25s;
}

.img_no_border img {
	border: none;
	box-shadow: none;
}

.insight_img {
	width: 75%;
	margin: auto;
}

.medium_img {
	width: 50%;
	margin: auto;
}

.zoom_active {
	z-index: 3;
	background: rgba(0,0,0,.95);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	color: #ffffff;
	cursor: zoom-out;
/*	display: flex;*/
	transition: background ease .5s;
}

.feature_img.zoom_active {
	width: 100%;
}

.zoom_background_light.zoom_active {
	background: rgba(251, 244, 223, .98);
}

.zoom_active img {
	position: fixed;
	z-index: 3;
	top: 6vh;
	width: 100%;
	height: 92vh;
	max-height: 100vh;
	max-width: 100vw;
	object-fit: contain;
	cursor: zoom-out;
	margin: auto;
	border: none;
	box-shadow: none;
	align-items: center;
	transition: width ease .75s;
/*	animation: slideOutNext .75s ease 1;*/
}

.insight_img.zoom_active {
		width: 100%;
		margin: 0;
	}

.insight_img.zoom_active svg,
.insight_img.zoom_active video
{
	width: 90vw;
	margin: auto;
}

.project_content p {
	font-size: .75rem;
	font-weight: 500;
	line-height: 1.5rem;
	margin: 0 0 2em 0;
}

.project_overview {
	margin: 0 1em;
}

.overview_title {
	font-size: 1.25rem;
	font-weight: 500;
	padding: .5em 0;
	border-bottom: 2px solid #000;
	margin: .75em 0;
}


.project_insight {
	background: #E8D9C4;
	padding: 3em 1em 1em 1em;
	opacity: 0%;
	transition: all ease 1s;
}

.insight_title {
	font-family: 'passion_one_regular', sans-serif;
	font-size: 3rem;
	color: #CEB997;
	padding: .25em 0;
	border-bottom: 2px solid #000;
	margin: .25em 0;
}

.in_view_shift_insight {
	opacity: 100%;
	transform: translateY(-6em);
	transition: all ease 1s;
}

#sheep1_container {
	
}

.sheep_eyes {
	animation: sheep_blink 12s ease infinite;
}

.sheep_body {
	animation: sheep_leaning_down_large_screen 10s ease infinite alternate;
}

.sheep_head {
		transform: translateX(2%);
		animation: sheep_head_leaning_down_large_screen 10s ease infinite alternate;
	}

.sheep_jaw {
	transform: rotate(-36deg) translate(-31.5%, 55%);
	animation: sheep_mouth_chewing_large_screen 10s ease infinite alternate;
}

.more_work {
	color: #000;
	margin: 3em 0;
}

.more_work_title {
	font-family: 'passion_one_regular', sans-serif;
	font-size: 2rem;
	border-bottom: 2px solid #000;
	margin: .5em 0;
	text-align: center;
}

.project_select {
	display: flex;
}

.project_select img {
	width: 1.5em;
	margin: 1em 0;
}


.more_work_left,
.more_work_right {
	width: 50%;
	text-decoration: none;
}

.more_work_left {
	text-align: right;
	border-right: 2px solid #000;
	padding: 0 1.5em 0 0;
}

.more_work_right {
	padding: 0 0 0 1.5em;
}

.more_work_left p,
.more_work_right p {
	font-family: 'passion_one_regular', sans-serif;
	font-size: 2rem;
	line-height: 2rem;
	width: 100%;
	color: #F3E5CA;
	-webkit-text-stroke: 1px #000;
	text-stroke: 1px #000;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.more_work_left img {
	margin-left: auto;
}

.more_work_right img {
	transform: rotate(180deg);
}



.project_column_left {
	z-index: 1;
	margin: 0;
	width: auto;
	grid-area: p_left;
	display: flex;
	flex-direction: column;
}

.top_vertical_line { 
	height: 70vh;
	margin: 1em 0 1em .75em;
}

.top_vertical_line::before {
	display: inline-block;
	content: '';
	position: static;
	height: 100%;
	border-left: 2px solid #FF1C31;
	animation:  revealTopToBottom 1s ease 1;
}

.btm_vertical_line {
	border-left: 2px solid #FF1C31;
	height: 100%;
	margin: 1em 0 0 .75em;
}


/*
.flex_img_grid {
	width: 75%;
    margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 1em;
}
*/

/*
.flex_img_grid > img {
	width: 200px;
	height: auto;
}
*/


.mw_title {
	font-family: 'passion_one_regular', sans-serif;
	width: 100%;
	text-align: center;
	font-size: 2rem;
	text-transform: uppercase;
}

.mw_intro_text {
	width: 95%;
	font-size: .85rem;
	margin: 1em auto 2em auto;
	text-align: center;
}

.mw_image_grid {
	width: 80%;
	margin: 2em auto;
	column-count: 1;
	column-gap: 1.5em;
    box-sizing: border-box;
}

.mw_image_wrapper {
    height: auto;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
	cursor: pointer;
}
	
.mw_column_image {
	border: 3px solid black;
	box-shadow: 5px 5px 0px black;
	margin-bottom: 1em;
}

.mw_insight_wrapper {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	background: rgba(56,55,52,.96);
	width: 100%;
	height: 100%;
	z-index: 2;
	overflow-y: scroll;
}

.close_btn {
	position: fixed;
	width: 2.5em;
	height: 2.5em;
	margin: 10vh 0 0 4vw;
	cursor: pointer;
}

.close_btn::before,
.close_btn::after{
	width: 100%;
	height: 10%;
	position: relative;
	display: block;
	content: "";
	background: white;
	border-radius: 2px;
	margin: auto 0;
}

.close_btn::before {
	transform: translateY(1.5em) rotate(45deg);
}

.close_btn::after {
	transform: translateY(calc(1.2em)) rotate(-45deg);
}

.mw_hero_card {
	background: black;
	width: 70%;
	margin: 18vh auto 0 auto;
	padding: 2em 2em 0 2em;
}

.mw_hero_img {
	width: 100%;
	margin: 0 auto;
	cursor: zoom-in;
}

.mw_hero_card p {
	color: #fff;
	width: 100%;
	margin: 0 auto;
	padding: 2em 0;
}

.mw_insight_img_wrapper {
	width: 70%;
	margin: 2em auto;
	cursor: zoom-in;
}

.drum_background {
	background: white;
}


/*
.in_view_shift_img {
	transform: translateY(2em);
	transition: transform ease 1s;
}
*/

.more_work {
	color: #000;
	margin: 3em 0;
}

.more_work_title {
	font-family: 'passion_one_regular', sans-serif;
	font-size: 2rem;
	border-bottom: 2px solid #000;
	margin: .5em 0;
	text-align: center;
}

.project_select {
	display: flex;
}

.project_select img {
	width: 1.5em;
	margin: 1em 0;
}


.more_work_left,
.more_work_right {
	width: 50%;
	text-decoration: none;
}

.more_work_left {
	text-align: right;
	border-right: 2px solid #000;
	padding: 0 1.5em 0 0;
}

.more_work_right {
	padding: 0 0 0 1.5em;
}

.more_work_left p,
.more_work_right p {
	font-family: 'passion_one_regular', sans-serif;
	font-size: 2rem;
	line-height: 2rem;
	width: 100%;
	color: #F3E5CA;
	-webkit-text-stroke: 1px #000;
	text-stroke: 1px #000;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.more_work_left img {
	margin-left: auto;
}

.more_work_right img {
	transform: rotate(180deg);
}



.project_column_left {
	z-index: 1;
	margin: 0;
	width: auto;
	grid-area: p_left;
	display: flex;
	flex-direction: column;
}

.top_vertical_line { 
	height: 70vh;
	margin: 1em 0 1em .75em;
}

.top_vertical_line::before {
	display: inline-block;
	content: '';
	position: static;
	height: 100%;
	border-left: 2px solid #FF1C31;
	animation:  revealTopToBottom 1s ease 1;
}

.btm_vertical_line {
	border-left: 2px solid #FF1C31;
	height: 100%;
	margin: 1em 0 0 .75em;
}







/*---------media queries-----------*/

@media (min-width: 900px) {
	
	
	#project_grid {
/*		display: grid;*/
		width: 100%;
/*
		margin: 0 auto;
		grid-template-rows: auto auto;
		grid-template-columns: auto auto 4fr auto;
		grid-template-areas: 
			"p_left p_title p_title p_title"
			"p_left p_body p_body p_body"
			;
		padding-bottom: 4em;
*/
	}
	
	.zoom_active img {
		top: 7vh;
	}
	
	.back_button {
		transform: translateX(calc(-2vw - 4.75em));
	}
	
	.back_button img {
		width: 2.5vw;
		height: 2.5vw;
	}
	
	.feature_overview_container {
		display: flex;
		margin: 0 0 10em 0;
	}
	
	.project_overview {
		margin: 0 1em 0 1em;
		width: 50%;
		display: flex;
		flex-direction: column;
	}
	
	.project_overview p {
		margin: 0 1em 2em 4em;
	}
	
	.overview_title {
		margin: 15vw 1em 0 .5em;
		border-top: 2px solid #000000;
		border-bottom: 0;
		padding: .25em 0;
	}
	
	
	.feature_img {
		width: 50%;
		order: 2;
/*		margin: 3em 0 0 auto;*/
		
/*
		grid-column: 1;
		grid-row: 1;
		margin: 2em auto;
		border: 2px solid #000;
		box-shadow: 5px 5px 0 #3B301D;
		cursor: zoom-in;
		transition: all ease .25s;
*/
	}
	
	
	.project_heading {
		position: absolute;
		background: #E8D9C4;
		width: 38%;
		transform: translateX(calc(-15vw - 4.75em));
		padding-left: calc(15vw + 6em);
/*
		transform: translateX(-3.75em);
		padding: 0 0 0 3.75em;
*/
	}

	.project_heading p {
		color: #E8D9C4;
		-webkit-text-stroke: 2px #000;
		text-stroke: 2px #000;
		letter-spacing: 2px;
		text-transform: uppercase;
		font-family: 'passion_one_regular', sans-serif;
		font-size: 4vw;
		padding: .5rem 0;
		max-width: 65%;
		line-height: 3vw;
	}
	
	
	
	
	.project_column_left {
		margin: 0 .875em 0 .875em;
	}
	
	.top_vertical_line {
		margin: 1em 0 1em .7rem; 
	}
	
	.insight_img {
		width: 40%;
	}
	
	.insight_img.zoom_active svg {
		height: 75vh;
		margin: auto;
	}
	
	.medium_img {
		width: 25%;
	}
	
	.img_right {
		margin: 0 4em 0 auto;
	}
	
	.img_left {
		margin: 0 auto 0 4em;
	}
	
	
	
	.project_insight * {
		width: 45%;
	}
	
	.insight_right.project_insight * {
		margin: 0 0 0 auto;
	}
	
	.insight_title {
		border-bottom: none;
		border-top: 2px solid #000000;
		margin-bottom: 0;
		padding: 0 4rem 0 0;
	}
	
	.project_insight p {
		transform: translateY(-2.5em);
		margin: 0 0 0 4rem;
	}
	
	.in_view_shift_insight {
		transform: translateY(-100%);
		transition: all ease 1s;
	}
	
	.tall_img.in_view_shift_insight {
		transform: translateY(-225%);
	}
	
	.project_wrapper {
		margin: 0 auto;
		width: 90%;
		
	}
	
	.more_work_title {
		width: 50%;
		margin: .5em auto;
	}
	
	.more_work_left:hover p,
	.more_work_right:hover p {
		color: #000;
		transition: all ease .75s;
	}
	
	.more_work_left p,
	.more_work_right p {
		font-size: 3rem;
		line-height: 3rem;
		-webkit-text-stroke: 2px #000;
		text-stroke: 2px #000;
		letter-spacing: 2px;
	}
	
	
	
}

@supports (-webkit-touch-callout: none) {
	.sheep_head {
		transform: translateX(2%);
		animation: sheep_head_leaning_down_small_screen 10s ease infinite alternate;
	}
	
}

@media (min-width: 1300px) {
	
	.feature_img {
		width: 100%;
	}
}


@media (min-width: 1475px) {
	
	.project_wrapper {
		margin: 0 auto;
		width: 70%;
		
	}
	
	.back_button {
		transform: translateX(calc(-15vw - 4.75em));
		margin-left: 7.5vw;
	}
	
}







@keyframes fadeUnderline {
	0% {
		border-bottom: 2px solid rgba(238, 44, 57, 0);
	}
	50% {
		border-bottom: 2px solid rgba(238, 44, 57, 0);
	}
	
	100% {
		border-bottom: 2px solid rgba(238, 44, 57, 1);
	}
}

@keyframes revealLeftToRight {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}

@keyframes revealLeftToRightDesktop {
	0% {
		width: 0;
	}
	100% {
		width: 80%;
	}
}


@keyframes revealTopToBottom {
	0% {
		height: 0;
	}
	100% {
		height: 100%;
	}
}

@keyframes slideTitleIn {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(calc(-15vw - 4.75em));
	}
}

@keyframes sheep_blink {
	25% {
		opacity: 100%;
	}
	
	25.1% {
		opacity: 0%;
	}
	
	26.8% {
		opacity: 0%;
	}
	
	26.9% {
		opacity: 100%;
	}
	
	95% {
		opacity: 100%;
	}
	
	95.1% {
		opacity: 0%;
	}
	
	96.8% {
		opacity: 0%;
	}
	
	96.9% {
		opacity: 100%;
	}

}

@keyframes sheep_leaning_down_large_screen {
	
	
	80% {
		transform: rotate(0deg) translate(0);
	}
	
	95% {
		transform: rotate(10deg) translate(9%, -3%);
	}
	
	100% {
		transform: rotate(10deg) translate(9%, -3%);
	}
}


@keyframes sheep_head_leaning_down_large_screen {
	
	
	80% {
		transform: rotate(0deg) translate(2%, 0);
	}
	
	95% {
		transform: rotate(12deg) translate(14%, 1%);
	}
	
	100% {
		transform: rotate(12deg) translate(14%, 1%);
	}
}


@keyframes sheep_head_leaning_down_small_screen {
	
	0% {
		transform: rotate(0deg) translateX(2%);
	}
	
	80% {
		transform: rotate(0deg) translateX(2%);
	}
	
	95% {
		transform: rotate(12deg) translateX(14%);
	}
	
	100% {
		transform: rotate(12deg) translateX(14%);
	}
}



@keyframes sheep_mouth_chewing_large_screen {
	10% {
		transform: rotate(-36deg) translate(-31.5%, 55%);
	}
	
	15% {
		transform: rotate(-35.25deg) translate(-31.5%, 55%);
	}
	
	20% {
		transform: rotate(-36deg) translate(-31.5%, 55%);
	}
	
	25% {
		transform: rotate(-35deg) translate(-31.5%, 55%);
	}
	
	30% {
		transform: rotate(-36deg) translate(-31.5%, 55%);
	}
	
	35% {
		transform: rotate(-35.25deg) translate(-31.5%, 55%);
	}
	
	40% {
		transform: rotate(-36deg) translate(-31.5%, 55%);
	}
	
	45% {
		transform: rotate(-35.5deg) translate(-31.5%, 55%);
	}
	
	80% {
		transform: rotate(-36deg) translate(-31.5%, 55%);
	}
	
	95% {
		transform: rotate(-35.25deg) translate(-31.5%, 55%);
	}
	
	98% {
		transform: rotate(-36deg) translate(-31.5%, 55%);
	}
	100% {
		transform: rotate(-35.5deg) translate(-31.5%, 55%);
	}
}

.disable-pointer-events {
  pointer-events: none;
}

.no_padding {
	padding: 0 0 0 0;
}